import React, { useState } from "react";
import "./FrameInstance.css";

const FrameInstance = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const images = [
    "/DSC00365.jpg",
    "/DSC07826.JPG",
    "/F1jv6qmWYAMfv8L.jpg",
    "/F0wnxTraIAAGyc3.jpg",
    "/F1jv6qmWYAMfv8L.jpg"
    
  ]; // Add more image URLs as needed

  return (
    <div className="photo-2023-09-21-16-08-29-parent">
      <img
        className="photo-2023-09-21-16-08-29-icon"
        alt=""
        src={images[currentImageIndex]}
      />
      <div className="frame-wrapper">
        <div className="frame-parent1">
          <div
            className="fluentarrow-next-20-regular-parent"
            onClick={handlePreviousClick}
          >
            <img
              className="fluentarrow-next-20-regular-icon"
              alt=""
              src="/fluentarrownext20regular.svg"
            />
            <div className="previous">previous</div>
          </div>
          <div
            className="fluentarrow-next-20-regular-parent"
            onClick={handleNextClick}
          >
            <div className="next">next</div>
            <img
              className="fluentarrow-next-20-regular-icon"
              alt=""
              src="/fluentarrownext20regular1.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameInstance;
