import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import IPhone13Mini4 from "./pages/IPhone13Mini4";
import Biniyam from "./pages/Biniyam";
import Solomon from "./pages/Solomon";
import Neba from "./pages/neba";
import Berut from "./pages/berutawit";
import Mekdem from "./pages/mekdem";
import Zelalem from "./pages/zelalem";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<IPhone13Mini4 />} />
      <Route path="/Biniyam" element={<Biniyam />} />
      <Route path="/Solomon" element={<Solomon />} />
       <Route path="/Neba" element={<Neba />} />
       <Route path="/Berut" element={<Berut />} />
       <Route path="/Mekdem" element={<Mekdem />} />
       <Route path="/Zelalem" element={<Zelalem />} />
    </Routes>
  );
}
export default App;
